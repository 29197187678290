/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      script={[{
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          "@context": "https://www.schema.org",
          "@graph": [{
            "@type": "Organization",
            "name": "Pointbee",
            "legalName": "Pointbee",
            "url": "https://pointbee.app/",
            "logo": "https://pointbee.app/logo.png",
            "foundingDate": "2019",
            "founders": [{
                "@type": "Person",
                "name": "Pablo Rocha"
              },
              {
                "@type": "Person",
                "name": "Arturo Jaime Garcia"
              },
              {
                "@type": "Person",
                "name": "Ramon Almaguer"
              }
            ],
            "contactPoint": [{
              "@type": "ContactPoint",
              "contactType": "customer service",
              "telephone": "+1 (956) 624-0448",
              "email": "hello@dotdotdata.com"
            },{
              "@type": "ContactPoint",
              "contactType": "sales",
              "telephone": "+1 (956) 624-0448",
              "email": "hello@dotdotdata.com"
            }],
            "sameAs": [
              "https://play.google.com/store/apps/details?id=com.dotdotdata.pointbee",
              "https://apps.apple.com/app/id1455555114",
              "https://facebook.com/pointbeeapp",
              "https://instagram.com/pointbee.app"
            ]
          }, {
            "@type": "SoftwareApplication",
            "name": "Pointbee",
            "operatingSystem": ["ANDROID", "IOS"],
            "applicationCategory": "https://schema.org/GameApplication",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "16"
            },
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            }
          }]
        })
      }]}
      title={title}
      titleTemplate={site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
