module.exports = {
  // App Info
  appstore_link: 'https://apps.apple.com/app/id1455555114', // Enter App Store URL.
  playstore_link: 'https://play.google.com/store/apps/details?id=com.dotdotdata.pointbee', // Enter Google Play Store URL.
  google_analytics_ID: 'UA-66485739-18', // Enter Google Analytics ID or ""
  presskit_download_link: 'https://dotdotdata.', // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: 'screenshot', // "screenshot" or "video"
  app_url: 'https://pointbee.app', // Domain of your website without path_prefix.
  path_prefix: '/', // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: 'Pointbee',
  app_price: 'Get points. Be rewarded.',
  app_description: 'A fun, new way to connect to the world around you. '
  + 'Get points for crossing into virtual Zones on our map. '
  + 'Points are then redeemed for rewards such as discounts, free meals, event tickets and more!',
  app_keywords: ['geolocation', 'android', 'ios', 'mobile', 'rewards'],

  // Personal Info
  your_name: 'Dot Dot Data',
  your_link: 'https://www.dotdotdata.com',
  your_city: 'McAllen, TX',
  email_address: 'hello@dotdotdata.com',
  facebook_username: 'pointbeeapp',
  instagram_username: 'pointbee.app',
  twitter_username: 'dotdotdata',
  github_username: null,
  youtube_username: null,

  // Features List
  features: [


    {
      title: 'How it Works',
      description: 'Pass by any of our Zones and collect points from retailers.',
      fontawesome_icon_name: 'adjust',
    },
    {
      title: 'Turn Your Points Into Rewards',
      description: 'Collect enough points from a retailer and exchange them for awesome rewards!',
      fontawesome_icon_name: 'star',
    },
    {
      title: 'Promote Your Business',
      description: 'If you are a business owner, you can promote your business with Pointbee and attract new customers with your rewards!',
      fontawesome_icon_name: 'arrow-alt-circle-up',
    },

  ],
  header_background: 'rgba(0, 0, 0, 0.1)',
  topbar_title_color: '#ffffff',
  cover_overlay_color_rgba: 'rgba(129, 129, 221, 0.8)',
  device_color: 'black', // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: '#ffffff',
  primary_text_color: '#000',
  content_width: '1170px',
  font: '"Helvetica Neue", sans-serif',
  link_color: '#666666',
  app_title_color: '#ffffff',
  app_price_color: '#ffffff',
  app_description_color: '#ffffff',
  feature_title_color: '#000000',
  feature_text_color: '#666666',
  feature_icons_foreground_color: 'rgba(129, 129, 221, 0.8)',
  feature_icons_background_color: '#e6e6e6',
  social_icons_foreground_color: '#666666',
  social_icons_background_color: '#e6e6e6',
  footer_text_color: '#666666',
}
